
import { useI18n } from "vue-i18n/index";
import axios from "axios";
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import ApiRoutes from "@/core/config/ApiRoutes";

interface QueryParams {
    code: string;
    name: string;
    supplier_intra_id: number;
    description: string;
}

export default defineComponent({
    name: "search-supplier-intra-modal",
    emit: ["refreshData"],
    props: {
        params: null,
        getParams: Function
    },
    components: {
        ErrorMessage,
        Field,
        Form,
    },
    setup(props, { emit }) {
        const { t, te } = useI18n();
        let suppliersIntra = ref([]);
        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const searchAnalyticAccountIntraModalRef = ref<null | HTMLElement>(null);
        const initialValues = {
            code: "",
            name: "",
            supplier_intra_id: 0,
            description: "",
        };
        const queryParams = ref<QueryParams>(props.params);
        Object.assign(queryParams.value, initialValues);

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const submit = (values, actions) => {

            if (!submitButtonRef.value) {
                return;
            }

            if (props.getParams) props.getParams(queryParams.value);

            hideModal(searchAnalyticAccountIntraModalRef.value);
        };

        const getSuppliersIntra = async () => {
            const response = await axios.get(ApiRoutes.suppliersIntra.list);

            suppliersIntra.value = response.data;
        };

        onMounted(() => {
            getSuppliersIntra();

            const modal = document.getElementById('kt_modal_search_analytic_account_intra');

            // if (modal) modal.addEventListener('hidden.bs.modal', function() {
            // });

            if (modal) modal.addEventListener('shown.bs.modal', function() {

                if (props.params) {
                    // Fill Intra fields
                    Object.assign(queryParams.value, props.params);
                }
            });
        });

        return {
            translate,
            suppliersIntra,
            queryParams,
            submit,
            submitButtonRef,
            searchAnalyticAccountIntraModalRef,
        };
    },
});
